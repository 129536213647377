import React, { useEffect, useState } from "react";
import { tableProps } from "../../../../../../styles/muiProps";
import { MaterialReactTable } from "material-react-table";
import { columns } from "./columns";
import { ListItemIcon, MenuItem, Typography } from "@mui/material";

import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import DeleteIcon from "@mui/icons-material/Delete";
import { get5d } from "./api";
import { useParams } from "react-router-dom";

const Project5d = () => {
  const { id } = useParams();
  const [data, setData] = useState({});
  useEffect(() => {
    console.log("hereeeeeee", id);

    const getData = async () => {
      const data = await get5d(id).then((res) => {
        console.log("5d ress", res);
        setData(res);
      });
    };

    getData();
  }, []);

  return (
    <MaterialReactTable
      // style={{ tableLayout: "auto" }}
      columns={columns}
      data={data || []}
      editDisplayMode="cell"
      // enableEditing={isEditTable}
      enableColumnActions={false}
      // enableRowActions
      positionActionsColumn="last"
      // enableColumnResizing={true}
      layoutMode={"grid"}
      {...tableProps}
      muiTableHeadCellProps={({ cell }) => ({
        sx: {
          borderRight: "1px solid rgba(224,224,224,1)",
          backgroundColor: "hsla(250, 86%, 97%, 1)",
          fontSize: "13px",
          pt: 2,
        },
      })}
      muiTableBodyCellProps={{
        sx: {
          padding: "8.5px 8px",
          // textAlign: "center",
          borderRight: "1px solid rgba(224,224,224,1)",
        },
      }}
      displayColumnDefOptions={{
        "mrt-row-actions": {
          size: 5,
          muiTableHeadCellProps: {
            align: "center",
          },
          muiTableBodyCellProps: {
            align: "center",
            sx: {
              p: 0,
              "& button": {
                ml: 0,
              },
            },
          },
        },
      }}
      // renderTopToolbarCustomActions={() => (
      //     <h4>Cost Comparison</h4>
      // )}
      defaultColumn={{
        minSize: 60, //allow columns to get smaller than default
        // maxSize: 160, //allow columns to get larger than default
        size: 80, //make columns wider by default
      }}
      initialState={{
        pagination: { pageSize: 30 },
        columnVisibility: { Component: false },
      }}
      // state={{
      //     isLoading,
      // }}
    />
  );
};

export default Project5d;
