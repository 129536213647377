import React from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
// import LabelledBox from '../../../../../../../common/components/LabelledBox'
import { Box, Button } from "@mui/material";

import { useMrqContext } from "../../MrqContext";
import { useMcsContext } from "../../../MCS.context";
import AddPoModal from "./AddPoModal";
import {
  bulkDescriptionMrqMaterialsCols,
  bulkMrqMaterialsCols,
  mrqMaterialsCols,
  taggedMrqMaterialsCols,
} from "../../../Components/cols/mrqMaterialsCols";
import { mrqMaterialsMcsDataCols } from "../../../Components/cols/mrqMaterialsMcsCols";
import LabelledBox from "../../../../../../../../components/widgets/LabelledBox";
import { lightTable } from "../../../../../../../../styles/muiProps";

const MrqMaterialsTable = () => {
  const handleSaveItem = async (values) => {
    console.log({ values });
  };

  const { mrqData, setOpenAddPoModal } = useMrqContext();
  const { setSelectedMrqMaterials } = useMcsContext();

  const mrqMaterialsRowsWithoutSorting =
    mrqData?.mrqMaterials.map((mm) => {
      if (mm?.materials?.[0].item) {
        return { ...mm, item: mm.materials[0].item };
      } else {
        return mm;
      }
    }) || [];

  const mrqMaterialsRows = mrqMaterialsRowsWithoutSorting?.[0]?.item
    ? mrqMaterialsRowsWithoutSorting.sort((a, b) => a.item - b.item)
    : mrqMaterialsRowsWithoutSorting;
  // console.log('from mrqMaterials table : ', data);
  const generalTableAttrs = {
    data: mrqMaterialsRows,
    enableRowSelection: true,

    positionActionsColumn: "last",
    onEditingRowSave: { handleSaveItem },
    renderTopToolbarCustomActions: ({ table }) => {
      const selectedRows = table.getSelectedRowModel();

      const isDisabled = () => {
        if (selectedRows?.rows[0]) {
          return false;
        }
        return true;
      };

      return (
        <Box sx={{ display: "flex", gap: "1rem", p: "4px" }}>
          <Button
            variant="contained"
            disabled={isDisabled()}
            onClick={() => {
              setOpenAddPoModal(true);
              setSelectedMrqMaterials(
                selectedRows.rows.map((r) => {
                  console.log(r.original);
                  return {
                    ...r.original,
                    //, id: r.original.id[0]
                  };
                })
              );
            }}
          >
            Add To PO
          </Button>
          <Button
            variant="contained"
            disabled={isDisabled()}
            onClick={() => {
              setSelectedMrqMaterials(
                selectedRows.rows.map((r) => {
                  return { ...r.original };
                })
              );
            }}
          >
            Create Bid valuation
          </Button>
        </Box>
      );
    },
    ...lightTable,
    enableTopToolbar: true, //overriding lightTable
    // state: { columnVisibility },
    // onColumnVisibilityChange: { setColumnVisibility },
  };

  const mcsCols = mrqMaterialsMcsDataCols.filter(
    (c) => !["mrqNumber", "srvNumber"].includes(c.accessorKey)
  );

  const descCols = bulkDescriptionMrqMaterialsCols.filter(
    (c) => !["wt"].includes(c.accessorKey)
  );

  const isTaggedMaterials =
    mrqMaterialsRows?.[0]?.materials[0]?.taggedMaterial?.id;
  const isBulkMaterials = mrqMaterialsRows?.[0]?.materialCode;

  const taggedTable = useMaterialReactTable({
    ...generalTableAttrs,
    columns: [
      ...taggedMrqMaterialsCols,
      // ...mcsCols
    ],
  });

  const bulkTable = useMaterialReactTable({
    ...generalTableAttrs,
    columns: [
      ...bulkMrqMaterialsCols,
      // ...mcsCols
    ],
  });

  const bulkDescriptionTable = useMaterialReactTable({
    ...generalTableAttrs,
    columns: [
      // ...bulkDescriptionMrqMaterialsCols,
      ...descCols,
      // ...mcsCols
    ],
  });

  const table = isTaggedMaterials
    ? taggedTable
    : isBulkMaterials
    ? bulkTable
    : bulkDescriptionTable;

  return (
    <LabelledBox label="Mrq Materials">
      <AddPoModal />
      <MaterialReactTable table={table} />
      {/* <Button variant='contained' sx={{ mt: 1 }}>Save</Button> */}
    </LabelledBox>
  );
};

export default MrqMaterialsTable;
