export const transformsPVEstData = (data) => {
  const manhours = [
    data?.data?.DGV_Estimation_Est[0]["Total Manhour"],
    data?.data?.DGV_Estimation_Est[1]["Total Manhour"],
    data?.data?.DGV_Estimation_Est[2]["Total Manhour"],
    "",
    4.9,
    1.55,
    4.95,
    1.8,
    1.55,
    1.55,
    1.55,
    10.65,
  ];
  const weights = data?.data?.DGV_Est_BOM.map((row) => row["Total Weight"]);
  const pvEstData = [
    ...data?.data?.DGV_Shell_BOM,
    ...data?.data?.DGV_Nozzle_Material_Description,
  ]
    .map((row, i) => {
      const Components = row.Description || row["Material Description"] || "";
      const weight = row["Total Weight"] || "";
      const cost = row["Cost (US $)"] || row["Total Cost (US $)"];

      return {
        ...row,
        Components,
        // "Total Weight (Kg)": weight,
        "Total Material Cost (US $)": cost,
        "Total Manhour": manhours[i],
        "Total Weight (Kg)": weights[i],
        Unit: row.Unit ? row.Unit : "No.",
      };
    })
    .filter((row) => row.Components !== "Total");

  return pvEstData;
};
