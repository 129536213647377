import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Box,
} from "@mui/material";
import { InsertDriveFile, Folder } from "@mui/icons-material";
import { secondAxios as axios } from "../../../../../api/axios";
import SP_Icon from "../../../../../images/sp_icon.png";

const FileExplorerDialog = ({ open, onClose, getData }) => {
  const [fileList, setFileList] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);

  // Fetch file list from SharePoint
  const getFileList = async () => {
    setLoading(true);
    try {
      const resp = await axios.get(`/sharepoint/all-files`, {
        params: {
          filePath: "/drive/root:/PV-CDT-001%2F2%2E%20Estimation%2F0",
          siteId:
            "chinookdt189.sharepoint.com,64dc97a0-0b9b-4dd3-883c-2e95aa812e41,491ce48a-a146-4cb4-9ff8-1f01d53f5070",
        },
      });
      setFileList(resp.data);
    } catch (error) {
      console.error("Error fetching file list:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) getFileList();
  }, [open]);

  // Handle file selection
  const handleFileSelect = (file) => {
    setSelectedFile(file);
  };

  // Fetch file data after selection
  const handleGetData = async () => {
    if (!selectedFile) return;

    setLoading(true);
    try {
      const resp = await axios.get(`/sharepoint/file`, {
        params: { fileId: selectedFile.id },
      });
      console.log("File Data:", resp.data);

      // Handle fetched data here
    } catch (error) {
      console.error("Error fetching file data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      sx={{
        "& .MuiDialog-paper": {
          width: "80%",
          height: "50vh",
          padding: "16px",
        },
      }}
    >
      {/* Dialog Title */}
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1, // Add spacing between image and text
          }}
        >
          <Typography variant="h6">
            Select Estimation data JSON File from Sharepoint
          </Typography>
          <img
            src={SP_Icon}
            alt="Logo"
            style={{
              width: 60,
              height: 60,
              objectFit: "contain",
            }}
          />
        </Box>
      </DialogTitle>

      {/* Dialog Content */}
      <DialogContent dividers>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh", // Center it vertically
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <List>
            {fileList.map((file) => (
              <ListItem
                key={file.id}
                button
                selected={selectedFile?.id === file.id}
                onClick={() => handleFileSelect(file)}
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: "#f0f0f0",
                    "&:hover": {
                      backgroundColor: "#e0e0e0",
                    },
                  },
                  borderBottom: "1px solid #ddd",
                }}
              >
                {/* File Icon */}
                <ListItemIcon>
                  {file.name.endsWith("/") ? (
                    <Folder color="primary" />
                  ) : (
                    <InsertDriveFile color="action" />
                  )}
                </ListItemIcon>

                {/* File Name and Details */}
                <ListItemText
                  primary={
                    <Typography variant="body1" noWrap>
                      {file.name}
                    </Typography>
                  }
                  secondary={
                    <>
                      <Typography variant="body2" color="textSecondary" noWrap>
                        Last Modified:{" "}
                        {new Date(file.lastModifiedDateTime).toLocaleString()}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" noWrap>
                        Created At:{" "}
                        {new Date(file.createdDateTime).toLocaleString()}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
            ))}
          </List>
        )}
      </DialogContent>

      {/* Dialog Actions */}
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={() => {
            getData(selectedFile.id, selectedFile.name);
          }}
          disabled={!selectedFile || loading}
          variant="contained"
          color="primary"
        >
          Get Data
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FileExplorerDialog;
