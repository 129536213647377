import React, { createContext, useContext } from "react";
import { getMrqData, getMrqPos, getMrqs } from "../api";
import { useMcsContext } from "../MCS.context";
import { getMrqBid } from "../BidTab/api";

const MrqContext = createContext();
export const useMrqContext = () => useContext(MrqContext);

function MrqContextProvider({ children }) {
  const { project, setSelectedMrq } = useMcsContext();

  const mrqData = project.selectedMrq;
  const [mrqList, setMrqList] = React.useState([]);
  const [mrqSelection, setMrqSelection] = React.useState({
    selectedMrq: "",
    revisions: [],
    selectedRevision: "",
  });

  const [BidTableRows, setBidTableRows] = React.useState();
  const [bidData, setBidData] = React.useState();

  const [openAddPoModal, setOpenAddPoModal] = React.useState(false);
  const handleMrqChange = (event) => {
    const mrqId = event.target.value;
    const selectedMrq = mrqList.find((mrq) => mrq.id === mrqId);
    const revisions = selectedMrq?.revs ? selectedMrq.revs : [];

    setMrqSelection({
      selectedMrq: mrqId,
      revisions: revisions,
      selectedRevision: "",
    });
  };

  // Handler for selecting revision
  const handleRevisionChange = (event) => {
    setMrqSelection({ ...mrqSelection, selectedRevision: event.target.value });
  };

  React.useEffect(() => {
    const fetchMrqList = async () => {
      await getMrqs({ projectId: project.id }).then((res) => setMrqList(res));
    };

    fetchMrqList();
  }, []);

  // React.useEffect(() => {
  //   const fetchMrqThenBidData = async () => {
  //     if (mrqSelection.selectedMrq) {
  //       const mrq = await getMrqData(
  //         mrqSelection.selectedRevision || mrqSelection.selectedMrq
  //       );

  //       setSelectedMrq(mrq);
  //     }

  //     const bids = await getMrqBid(
  //       mrqSelection.selectedRevision || mrqSelection.selectedMrq
  //     );
  //     const existedPos = await getMrqPos(
  //       mrqSelection.selectedRevision || mrqSelection.selectedMrq
  //     );
  //     setBidData(bids ? bids : []);

  //     setBidTableRows();
  //     setBidTableRows(
  //       getBidTableRows({
  //         mrqData: mrq ? mrq : {},
  //         bidData: bids ? bids : [],
  //         existedPos,
  //       })
  //     );
  //   };

  //   fetchMrqThenBidData();
  // }, [mrqSelection.selectedRevision, mrqSelection.selectedMrq]);

  return (
    <MrqContext.Provider
      value={{
        project,

        mrqList,
        setMrqList,
        mrqSelection,
        setMrqSelection,
        mrqData,
        handleMrqChange,
        handleRevisionChange,

        BidTableRows,
        setBidTableRows,

        openAddPoModal,
        setOpenAddPoModal,
      }}
    >
      {children}
    </MrqContext.Provider>
  );
}
export { MrqContextProvider, MrqContext };
