import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { AddToFabricationColumns } from "./columns";
import { minimalMuiTableProps } from "../../../../../styles/muiProps";
import { createBulkFabrication } from "../../CommonFeatures/PCSTab/Fabrication/api";
import { useParams } from "react-router-dom";

const AddToFabricationDialog = ({ open, onClose, data }) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    // console.log("fabrication data", data);
  }, [open, data]);

  const handleRowSelectionChange = (selected) => {
    setSelectedRows(selected);
  };

  const table = useMaterialReactTable({
    columns: AddToFabricationColumns,
    data: data || [],
    enableRowSelection: true,
    ...minimalMuiTableProps,
  });

  const handleConfirm = () => {
    const rows = table.getSelectedRowModel().rows?.map((row) => ({
      estimatedHours: row.original["Total Manhour"],
      materialCode: row.original["Components"],
      materialDescription: row.original["Components"],
    }));
    console.log(rows);
    if (rows) createBulkFabrication(id, rows);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Add to Fabrication</DialogTitle>
      <DialogContent>
        <MaterialReactTable table={table} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button variant="contained" onClick={handleConfirm}>
          Add to Fabrication
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddToFabricationDialog;
