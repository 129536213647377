// import { MenuItem } from "@mui/material";
// import Box from "@mui/material";
import { Chip, Box, MenuItem, IconButton } from "@mui/material";
import PostAddIcon from "@mui/icons-material/PostAdd";

export const FabInitialData = {
  materialDescription: "",
  materialCode: "",
  specs: "",
  members: "",
  estimatedHours: "",
  targetDate: "",
  actualhours: "",
  // hoursLeft: "",
  progress: "",
  status: "",
};

export const fabricationColumns = (users, openTimesheet) => [
  {
    accessorKey: "id",
    header: "ID",
    isVisible: false,
    size: 0,
    enableHiding: true,
    enableEditing: false,
  },
  // {
  //   accessorKey: "materialCode",
  //   header: "Material Code",
  // },
  {
    accessorKey: "materialDescription",
    header: "Material Description",
    size: 220,
  },
  {
    accessorKey: "scope",
    header: "Scope",
  },
  {
    accessorKey: "members",
    header: "Members",
    size: 80,
    enableSorting: false,
    Cell: ({ cell, row }) => {
      return (
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
          {cell.getValue()
            ? cell
                .getValue()
                .split(",")
                .map((user, index) => (
                  <Chip key={index} label={user} size="small" />
                ))
            : ""}
        </Box>
      );
    },
    muiEditTextFieldProps: ({ value, onChange }) => ({
      children: users?.map((user, index) => (
        <MenuItem key={index} value={user}>
          {user}
        </MenuItem>
      )),
      select: true,
      SelectProps: {
        multiple: true,
        renderValue: (selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((value, index) => {
              console.log("selected", value);
              return <Chip key={index} label={value} />;
            })}
          </Box>
        ),
      },
    }),
  },
  {
    accessorKey: "targetDate",
    header: "Actual Start",
    size: 80,
    muiEditTextFieldProps: () => ({
      type: "date",
    }),
  },
  {
    accessorKey: "endDate",
    header: "Actual End",
    size: 80,
    muiEditTextFieldProps: () => ({
      type: "date",
    }),
  },
  {
    accessorKey: "estimatedHours",
    header: "Estimated Hours",
    size: 50,
    muiEditTextFieldProps: () => ({
      type: "number",
    }),
  },
  {
    accessorKey: "actualhours",
    header: "Actual hours",
    size: 50,
    muiEditTextFieldProps: () => ({
      type: "number",
    }),
  },
  // {
  //     accessorKey: 'hoursLeft',
  //     header: 'hoursLeft',
  // },
  {
    accessorKey: "progress",
    header: "Progress",
    size: 50,
    muiEditTextFieldProps: () => ({
      type: "number",
    }),
  },
  {
    accessorKey: "status",
    header: "Status",
    muiEditTextFieldProps: () => ({
      children: ["Not Started", "Progress", "Hold", "Completed"].map((func) => (
        <MenuItem key={func} value={func}>
          {func}
        </MenuItem>
      )),
      select: true,
    }),
    size: 80,
  },
  {
    accessorKey: "timesheet",
    header: `Timesheet`,
    enableEditing: false,
    enableSorting: false,
    enableColumnFilter: false,
    enableColumnActions: false,
    size: 20,
    Header: () => <PostAddIcon />,
    Cell: ({ row }) => (
      <IconButton
        onClick={() => {
          openTimesheet();
        }}
        // disabled={project_members?.includes(currentUser) || projectLeads.includes(currentUser) ? false : true}
      >
        <PostAddIcon />
      </IconButton>
    ),
  },
];
