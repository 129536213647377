import { Box, Stack } from "@mui/material";

export const estCols = [
  {
    accessorKey: "System",
    header: "System",
    size: 220,
    enableHiding: false,
    enableSorting: false,
  },
  {
    accessorKey: "Category",
    header: "Category",
    size: 120,
    enableHiding: false,
    enableSorting: false,
  },
  {
    accessorKey: "Component",
    header: "Component",
    size: 120,
    enableHiding: false,
    enableSorting: false,
  },
  {
    accessorKey: "SubComponent",
    header: "SubComponent",
    size: 120,
    enableHiding: false,
    enableSorting: false,
  },
  {
    accessorKey: "TagNumber",
    header: "TagNumber",
    size: 120,
    enableHiding: false,
    enableSorting: false,
  },
  {
    accessorKey: "Size/Rating",
    header: "Size/Rating",
    size: 120,
    enableHiding: false,
    enableSorting: false,
  },
  {
    accessorKey: "Material",
    header: "Material",
    size: 120,
    enableHiding: false,
    enableSorting: false,
  },
  {
    accessorKey: "Manufacturer",
    header: "Manufacturer",
    size: 120,
    enableHiding: false,
    enableSorting: false,
  },
  {
    accessorKey: "Model #",
    header: "Model #",
    size: 120,
    enableHiding: false,
    enableSorting: false,
  },
  {
    accessorKey: "Qty",
    header: "Qty",
    size: 50,
    enableHiding: false,
    enableSorting: false,
  },
  {
    accessorKey: "UOM",
    header: "UOM",
    size: 120,
    enableHiding: false,
    enableSorting: false,
  },
  {
    accessorKey: "Item Cost$",
    header: "Item Cost$",
    size: 120,
    enableHiding: false,
    enableSorting: false,
  },
];

export const pvEstCols = [
  // {
  //   accessorKey: "Matreial Code",
  //   header: "Material Code",
  //   size: 20,
  //   enableHiding: false,
  //   enableSorting: false,
  //   Cell: ({ cell }) => (
  //     <>
  //       {cell
  //         .getValue()
  //         .split(",")
  //         .map((mc) => (
  //           <div key={mc}>- {mc}</div>
  //         ))}
  //     </>
  //   ),
  // },
  {
    accessorKey: "Components",
    header: "Components / Description",
    size: 240,
    enableHiding: false,
    enableSorting: false,
  },
  {
    accessorKey: "Material Description",
    header: "Material Description",
    size: 240,
    enableHiding: false,
    enableSorting: false,
  },
  {
    accessorKey: "Qty",
    header: "Qty",
    size: 50,
    enableHiding: false,
    enableSorting: false,
  },
  {
    accessorKey: "Unit",
    header: "Unit",
    size: 50,
    enableHiding: false,
    enableSorting: false,
  },
  {
    accessorKey: "Total Weight (Kg)",
    header: "Total Weight (Kg)",
    size: 50,
    enableHiding: false,
    enableSorting: false,
  },
  {
    accessorKey: "Total Material Cost (US $)",
    header: "Total Material Cost (US $)",
    size: 100,
    enableHiding: false,
    enableSorting: false,
  },
  {
    accessorKey: "Total Manhour",
    header: "Total Manhour",
    size: 50,
    enableHiding: false,
    enableSorting: false,
  },
  {
    accessorKey: "Remarks",
    header: "Remarks",
    size: 100,
    enableHiding: false,
    enableSorting: false,
  },
];

export const trimListCols = [
  {
    accessorKey: "Description",
    header: "Description",
    size: 220,
    enableHiding: false,
    enableSorting: false,
  },
  {
    accessorKey: "Cost",
    header: "Cost",
    size: 220,
    enableHiding: false,
    enableSorting: false,
  },
  {
    accessorKey: "ManHours",
    header: "ManHours",
    size: 220,
    enableHiding: false,
    enableSorting: false,
  },
];

export const pvSumEst = [
  {
    accessorKey: "Items",
    header: "Items",
    size: 100,
    enableHiding: false,
    enableSorting: false,
  },
  {
    accessorKey: "Value",
    header: "Value",
    size: 50,
    enableHiding: false,
    enableSorting: false,
  },
  {
    accessorKey: "Manhour Rate (US $)",
    header: "Manhour Rate (US $)",
    size: 50,
    enableHiding: false,
    enableSorting: false,
  },
  {
    accessorKey: "Mark Up (%)",
    header: "Mark Up (%)",
    size: 50,
    enableHiding: false,
    enableSorting: false,
  },
  {
    accessorKey: "Total Cost (US $)",
    header: "Total Cost (US $)",
    size: 50,
    enableHiding: false,
    enableSorting: false,
  },
  {
    accessorKey: "Remarks",
    header: "Remarks",
    size: 50,
    enableHiding: false,
    enableSorting: false,
  },
];

const EstimationColumns = () => [
  {
    accessorKey: "Material Code",
    header: "Material Code",
    size: 120,
    enableSorting: false,
    // muiTableHeadCellProps: { align: "center" },
  },
  {
    accessorKey: "Material Description",
    header: "Material Description",
    size: 220,
    enableHiding: false,
    enableSorting: false,
  },
  // {
  //   accessorKey: "Actual Weight",
  //   header: "Actual Weight",
  //   size: 50,
  //   enableSorting: false,
  //   muiTableBodyCellProps: { align: "right" },
  //   // muiTableHeadCellProps: { align: "center" },
  // },
  {
    accessorKey: "Estimated Weight",
    header: "Estimated Weight",
    size: 50,
    enableSorting: false,
    muiTableBodyCellProps: { align: "right" },
    // muiTableHeadCellProps: { align: "center" },
  },
  {
    accessorKey: "Plate to be procured",
    header: "Plate to be procured",
    size: 200,
    enableSorting: false,
    // muiTableHeadCellProps: { align: "center" },
  },
  {
    accessorKey: "Qty",
    header: "Qty",
    size: 50,
    enableSorting: false,
    muiTableBodyCellProps: { align: "right" },
  },
  {
    accessorKey: "Unit",
    header: "Unit",
    size: 70,
    enableSorting: false,
    // muiTableHeadCellProps: { align: "center" },
  },
  {
    accessorKey: "Unit Cost",
    header: "Unit Cost",
    size: 70,
    enableSorting: false,
    muiTableBodyCellProps: { align: "right" },
    // muiTableHeadCellProps: { align: "center" },
  },
  {
    accessorKey: "Total Cost",
    header: "Total Cost",
    enableSorting: false,
    muiTableBodyCellProps: { align: "right" },
    // muiTableHeadCellProps: { align: "center" },
    size: "50",
  },
  {
    accessorKey: "Manhours",
    header: "Manhours",
    size: 50,
    enableSorting: false,
    muiTableBodyCellProps: { align: "right" },
    Cell: ({ cell }) => (
      <>{cell.getValue() ? parseFloat(cell.getValue()).toFixed(2) : ""}</>
    ),
    // muiTableHeadCellProps: { align: "center" },
  },
  {
    accessorKey: "Remarks",
    header: "Remarks",
    enableSorting: false,
    // muiTableHeadCellProps: { align: "center" },
    // size: "40px"
  },
];

export const SummaryColumns = () => {
  return [
    {
      accessorKey: "Summary",
      header: "Summary",
      size: 150,
      enableSorting: false,
      muiTableHeadCellProps: { align: "center" },
    },
    {
      accessorKey: `estimated`,
      header: "Est.",
      size: 100,
      enableSorting: false,
      muiTableBodyCellProps: { align: "right" },
      muiTableHeadCellProps: { align: "center" },
    },
    {
      accessorKey: "% Added",
      header: "% Added",
      size: 50,
      enableSorting: false,
      muiTableBodyCellProps: { align: "right" },
      muiTableHeadCellProps: { align: "center" },
    },
    {
      accessorKey: "Total",
      header: "Total",
      size: 100,
      enableSorting: false,
      muiTableBodyCellProps: { align: "right" },
      muiTableHeadCellProps: { align: "center" },
    },
  ];
};

export const NozzleEstimationColumns = () => {
  return [
    {
      accessorKey: "Mark",
      header: "Mark",
      size: 30,
      enableSorting: false,
      muiTableHeadCellProps: { align: "center" },
    },
    {
      accessorKey: "Flange_Weight",
      header: "Flange Weight (Kg).",
      size: 30,
      enableSorting: false,
      muiTableHeadCellProps: { align: "center" },
    },
    {
      accessorKey: "Pipe_Weight",
      header: "Pipe Weight (Kg)",
      size: 30,
      enableSorting: false,
      muiTableHeadCellProps: { align: "center" },
    },
    {
      accessorKey: "Pad_Weight",
      header: "Pad Weight (Kg)",
      size: 30,
      enableSorting: false,
      muiTableHeadCellProps: { align: "center" },
    },
    {
      accessorKey: "Total_Wieght",
      header: "Total Weight (Kg)",
      size: 30,
      enableSorting: false,
      muiTableHeadCellProps: { align: "center" },
    },
    {
      accessorKey: "Model_Wieght",
      header: "3D Model Weight (Kg)",
      size: 30,
      enableSorting: false,
      muiTableHeadCellProps: { align: "center" },
    },
    {
      accessorKey: "Weld_Manhour",
      header: "Weld Manhour",
      size: 30,
      enableSorting: false,
      muiTableHeadCellProps: { align: "center" },
    },
    {
      accessorKey: "Bolt_Manhour",
      header: "Bolt Manhour",
      size: 30,
      enableSorting: false,
      muiTableHeadCellProps: { align: "center" },
    },
    {
      accessorKey: "NDT_Cost",
      header: "NDT Cost ($)",
      size: 30,
      enableSorting: false,
      muiTableHeadCellProps: { align: "center" },
    },
  ];
};

export const AddToFabricationColumns = [
  // {
  //   accessorKey: "Material Code",
  //   header: "Material Code",
  //   size: 120,
  //   enableSorting: false,
  //   // muiTableHeadCellProps: { align: "center" },
  // },
  {
    accessorKey: "Components",
    header: "Componenet / Description",
    size: 220,
    enableHiding: false,
    enableSorting: false,
  },
  {
    accessorKey: "Qty",
    header: "Quantity",
    size: 50,
    enableSorting: false,
    muiTableBodyCellProps: { align: "right" },
    Cell: ({ cell }) => (
      <>{cell.getValue() ? parseFloat(cell.getValue()).toFixed(2) : ""}</>
    ),
    // muiTableHeadCellProps: { align: "center" },
  },
  {
    accessorKey: "Total Manhour",
    header: "Total Manhour",
    size: 50,
    enableSorting: false,
    muiTableBodyCellProps: { align: "right" },
    Cell: ({ cell }) => (
      <>{cell.getValue() ? parseFloat(cell.getValue()).toFixed(2) : ""}</>
    ),
    // muiTableHeadCellProps: { align: "center" },
  },
];

export default EstimationColumns;
