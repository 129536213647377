import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Typography,
  Box,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import { Close, AddCircle, Edit, CheckCircle } from "@mui/icons-material";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { pvEstCols } from "./columns";
import { minimalMuiTableProps } from "../../../../../styles/muiProps";
import { transformsPVEstData } from "./utils";
import { secondAxios as axios } from "../../../../../api/axios";
import FileExplorerDialog from "./SPFileExploererDialog";
import SP_Icon from "../../../../../images/sp_icon.png";

const ValidateDialog = ({ open, onClose, data }) => {
  const [currentData, setCurrentData] = useState(data);
  const [table1Data, setTable1Data] = useState([]);
  const [table2Data, setTable2Data] = useState([]);
  const [selectedTable1, setSelectedTable1] = useState(0);
  const [selectedTable2, setSelectedTable2] = useState(1);
  const [validated, setValidated] = useState(false);
  const [engData, setEngData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState({
    open: false,
    message: "",
    type: "",
  });
  const [fileExplorerState, setFileExplorerState] = useState(false);

  useEffect(() => {
    if (data.length > 0) {
      console.log("vvvvvvvvvv", data);

      setTable1Data(data[0].data.DGV_Estimation_Est);
      setTable2Data(data[1].data.DGV_Estimation_Est);
      setValidated(false);
    }
  }, [open, data]);

  const getEngData = async (fileId, fileName) => {
    setLoading(true); // Start loading
    const siteId =
      "chinookdt189.sharepoint.com,64dc97a0-0b9b-4dd3-883c-2e95aa812e41,491ce48a-a146-4cb4-9ff8-1f01d53f5070";

    // const filePath =
    //   "/drive/root:/PV-CDT-001%2F2%2E%20Estimation%2F0:/children";
    // const fileName = "PV-CDT-001.json";

    try {
      setFileExplorerState(false);

      const resp = await axios.get(`/sharepoint/file-id`, {
        params: {
          siteId,
          fileId,
        },
      });
      console.log("Response:", resp.data);
      // data.push(resp.data);
      setCurrentData((prevData) => [
        ...prevData,
        { data: resp.data.fileContent.data, name: `SP-${fileName}` },
      ]);
      setFeedback({
        open: true,
        message: "Data loaded successfully!",
        type: "success",
      });
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);

      setFeedback({
        open: true,
        message: "Failed to load data. Please try again.",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };
  const handleCloseFeedback = () => {
    setFeedback({ open: false, message: "", type: "" });
  };
  const handleTableChange = (event, tableNumber) => {
    console.log("changing tabs", event.target.value, currentData);

    const newIndex = event.target.value;
    let newData;

    newData = currentData[newIndex].data.DGV_Estimation_Est;

    if (tableNumber === 1) {
      setSelectedTable1(newIndex);
      setTable1Data(newData);
    } else {
      setSelectedTable2(newIndex);
      setTable2Data(newData);
    }
    setValidated(false);
  };

  const findMatchingRow = (component, tableData) =>
    tableData.find((row) => row.Components === component);

  const getRowState = (row) => {
    if (!validated) return "none";

    const inTable1 = findMatchingRow(row.Components, table1Data);
    const inTable2 = findMatchingRow(row.Components, table2Data);

    if (!inTable2) return "deleted";
    if (!inTable1) return "new";

    const hasChanges = pvEstCols.some(
      (col) =>
        col.accessorKey !== "Components" &&
        inTable1[col.accessorKey] !== inTable2[col.accessorKey]
    );

    return hasChanges ? "edited" : "unchanged";
  };

  const getRowIcon = (state) => {
    if (!validated || state === "none") return null;
    switch (state) {
      case "deleted":
        return <Close color="error" />;
      case "new":
        return <AddCircle color="success" />;
      case "edited":
        return <Edit color="primary" />;
      case "unchanged":
        return <CheckCircle color="disabled" />;
      default:
        return null;
    }
  };

  const getRowStyle = (row, table) => {
    if (!validated) return {};

    const rowState = getRowState(row);
    if (table === 1 && rowState === "deleted") return { color: "red" };
    if (table === 2 && rowState === "new") return { color: "green" };

    return {};
  };

  const getCellStyle = (row, column, isTable2) => {
    if (!validated) return {};

    if (column.accessorKey === "Components") return {};

    const matchingRow = findMatchingRow(row.Components, table1Data);

    if (!matchingRow) return {};

    if (
      matchingRow &&
      row[column.accessorKey] !== matchingRow[column.accessorKey] &&
      isTable2
    ) {
      return { color: "#DC9B50" };
    }

    return {};
  };
  const handleLocalFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      // console.log("hhhhhhhhhhhhhhhhhhhhhhhh");

      const fileContent = await file.text();
      const jsonData = JSON.parse(fileContent);
      console.log("parsed data", jsonData.data);

      setCurrentData((prevData) => [
        ...prevData,
        { data: jsonData.data, name: `Local-${file.name}` },
      ]);

      setFeedback({
        open: true,
        message: "Local file loaded successfully!",
        type: "success",
      });
    } catch (error) {
      console.error("Error parsing JSON file:", error);
      setFeedback({
        open: true,
        message: "Failed to load local file. Please try again.",
        type: "error",
      });
    }
  };

  const generateColumns = (isTable2) =>
    pvEstCols.map((col) => ({
      ...col,
      Cell: ({ cell, row }) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            // whiteSpace: "nowrap",
            ...getRowStyle(row.original, isTable2 ? 2 : 1),
            ...getCellStyle(row.original, col, isTable2),
          }}
        >
          {col.accessorKey === "Components" &&
            getRowIcon(getRowState(row.original))}
          {cell.getValue()}
        </div>
      ),
    }));

  const table1 = useMaterialReactTable({
    columns: generateColumns(false),
    data: table1Data || [],
    // enableRowSelection: true,
    ...minimalMuiTableProps,
  });

  const table2 = useMaterialReactTable({
    columns: generateColumns(true),
    data: table2Data || [],
    // enableRowSelection: true,
    ...minimalMuiTableProps,
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xl"
      fullWidth
      sx={{
        "& .MuiDialog-paper": {
          width: "100%",
          height: "100vh",
          margin: 0,
          maxWidth: "100%",
          maxHeight: "100%",
          padding: "16px",
        },
      }}
    >
      <DialogTitle>Validation</DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent={"flex-end"}
        >
          <Grid item xs={1} justifySelf={"center"} alignSelf={"center"}>
            <Button variant="contained" onClick={() => setValidated(true)}>
              Validate
            </Button>
          </Grid>
          <Grid item xs={1} justifySelf={"flex-end"}>
            <Button variant="contained" component="label" color="secondary">
              Browse Files
              <input
                type="file"
                hidden
                accept=".json"
                onChange={handleLocalFileUpload}
              />
            </Button>
          </Grid>
          {/* <Grid item justifySelf={"flex-start"} xs={3}>
            <Button
              variant="contained"
              onClick={() => {
                setFileExplorerState(true);
              }}
              disabled={loading}
              startIcon={
                loading ? (
                  <CircularProgress size={20} />
                ) : (
                  <img
                    src={SP_Icon}
                    alt="Logo"
                    style={{
                      width: 30,
                      height: 20,
                      objectFit: "contain",
                    }}
                  />
                )
              }
            >
              {loading ? "Reading Data..." : "Get Engineering Data"}
            </Button>
          </Grid> */}
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={6}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: 3,
                  paddingBottom: 2,
                  paddingTop: 2,
                  // borderBottom: "1px solid #ddd",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{ display: "flex", alignItems: "center", gap: 1 }}
                >
                  <Close color="error" /> Deleted
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ display: "flex", alignItems: "center", gap: 1 }}
                >
                  <AddCircle color="success" /> New
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ display: "flex", alignItems: "center", gap: 1 }}
                >
                  <Edit color="primary" /> Edited
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ display: "flex", alignItems: "center", gap: 1 }}
                >
                  <CheckCircle color="disabled" /> Unchanged
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ marginTop: 2 }}>
            <Grid item xs={6}>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <InputLabel>Select Data for Table 1</InputLabel>
                  <Select
                    value={selectedTable1}
                    onChange={(e) => handleTableChange(e, 1)}
                  >
                    {currentData.map((est, index) => (
                      <MenuItem key={index} value={index}>
                        {est.type ? `${est.name}-rev-${index}` : est.name}
                      </MenuItem>
                    ))}
                    {/* {engData && (
                  <MenuItem key={data.length} value={data.length}>
                    {`Engineering Data`}
                  </MenuItem>
                )} */}
                  </Select>
                </FormControl>
              </Grid>
              <MaterialReactTable table={table1} />
            </Grid>
            <Grid item xs={6}>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <InputLabel>Select Data for Table 2</InputLabel>
                  <Select
                    value={selectedTable2}
                    onChange={(e) => handleTableChange(e, 2)}
                  >
                    {currentData.map((est, index) => (
                      <MenuItem key={index} value={index}>
                        {est.type ? `${est.name}-rev-${index}` : est.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <MaterialReactTable table={table2} />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
      <Snackbar
        open={feedback.open}
        autoHideDuration={4000}
        onClose={handleCloseFeedback}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseFeedback}
          severity={feedback.type}
          sx={{ width: "100%" }}
        >
          {feedback.message}
        </Alert>
      </Snackbar>
      <FileExplorerDialog
        open={fileExplorerState}
        onClose={() => {
          setFileExplorerState(false);
        }}
        getData={getEngData}
      />
    </Dialog>
  );
};

export default ValidateDialog;
