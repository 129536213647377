import { MaterialReactTable } from "material-react-table";
import React, { useContext } from "react";
import { table4d5dColumns } from "./columns";
import { scheduleData } from "./dummyData";
import { Box, Button, MenuItem, Stack } from "@mui/material";
import TimelineComponent from "./TimelineComponent";
import { useParams } from "react-router-dom";
// import { getEquip4d5d } from '../../PMSTab/4d5d/api'
// import Project4d5d from '../../PMSTab/4d5d'
import {
  getSchedule,
  getTrackItem,
  updateSchedule,
  update4D,
  getProjectData,
} from "./api";
import { tableWithBorderedCells } from "../../../../../../styles/muiProps";
import TrackItemDialog from "./TrackItemDialog";
import { AdjustedData } from "./dummyData";
import RowEditableTable from "../../../../../../components/Tables/RowEditableTable";
import { AuthContext } from "../../../../../../context/authContext";
// import Gantt from 'frappe-gantt'

const Project4d = () => {
  const { id: projectId } = useParams();
  const { accessToken } = useContext(AuthContext);
  const [rows, setRows] = React.useState([]);
  const [trackItemDialog, setTrackItemDialog] =
    React.useState(dialogInitalState);

  const handleSaveCell = (cell, value) => {
    console.log(cell, value);
    let id = cell.row.original.id;
    let column = cell.column.id;
    const updatedRows = rows?.map((row) =>
      row.id === id ? { ...row, [column]: value } : row
    );
    setRows(updatedRows);
    // reRender()
  };

  const onSave = async () => {
    updateSchedule({ scheduleTasks: rows });
  };

  const onTrackItem = async (row) => {
    console.log(row);
    const result = await getTrackItem(row.original.id).then((res) =>
      setTrackItemDialog((prev) => ({
        ...prev,
        open: true,
        item: row.original,
        data: res,
      }))
    );
  };

  const onClose = async () => {
    setTrackItemDialog(dialogInitalState);
  };

  React.useEffect(() => {
    const func = async () => {
      const tasks = await getProjectData(projectId, accessToken);
      console.log("4ddd", tasks);

      const filteredTasks = tasks.filter((t) => t.taskCode);
      const reducedTasks = filteredTasks.reduce((acc, task) => {
        const {
          taskCode,
          name,
          progress,
          startDate,
          targetDate,
          actualStart,
          actualEnd,
        } = task;

        if (!acc[taskCode]) {
          acc[taskCode] = {
            taskCode,
            tasks: [],
            completedTasks: 0,
            totalTasks: 0,
            completionRate: 0,
            startDate,
            targetDate,
            actualStart: actualStart || null,
            actualEnd: actualEnd || null,
          };
        }

        // Add task details
        acc[taskCode].tasks.push({
          name,
          progress,
          startDate,
          targetDate,
          actualStart,
          actualEnd,
        });

        // Update task counts
        acc[taskCode].totalTasks += 1;
        if (progress >= 100) acc[taskCode].completedTasks += 1;

        // Update startDate to the earliest date
        if (new Date(startDate) < new Date(acc[taskCode].startDate)) {
          acc[taskCode].startDate = startDate;
        }

        // Update targetDate to the latest date
        if (new Date(targetDate) > new Date(acc[taskCode].targetDate)) {
          acc[taskCode].targetDate = targetDate;
        }
        // console.log(acc[taskCode].actualStart);

        // Update actualStart to the earliest date
        if (
          actualStart &&
          (!acc[taskCode].actualStart ||
            new Date(actualStart) < new Date(acc[taskCode].actualStart))
        ) {
          // console.log("hereee");

          acc[taskCode].actualStart = actualStart;
        }

        // Update actualEnd to the latest date
        if (
          actualEnd &&
          (!acc[taskCode].actualEnd ||
            new Date(actualEnd) > new Date(acc[taskCode].actualEnd))
        ) {
          acc[taskCode].actualEnd = actualEnd;
        }

        // Calculate completion rate
        acc[taskCode].completionRate =
          (acc[taskCode].completedTasks / acc[taskCode].totalTasks) * 100;

        return acc;
      }, {});
      const tableArray = Object.values(reducedTasks).map((taskGroup) => ({
        name: taskGroup.taskCode,
        completedTasks: taskGroup.completedTasks,
        totalTasks: taskGroup.totalTasks,
        progress: taskGroup.completionRate.toFixed(0), // As a percentage
        plannedStart: taskGroup.startDate,
        plannedEnd: taskGroup.targetDate,
        actualStart: taskGroup.actualStart || null,
        actualEnd: taskGroup.actualEnd || null,
      }));

      tableArray.push({
        actualEnd: "2025-01-17",
        actualStart: "2025-02-05",
        completedTasks: 0,
        name: "PV-Fabrication",
        plannedEnd: "2025-01-20",
        plannedStart: "2025-01-05",
        progress: "100",
        totalTasks: 5,
      });
      const sortedData = tableArray.sort((a, b) => {
        // Handle cases where `plannedStart` might not exist or is null
        const dateA = a.plannedStart ? new Date(a.plannedStart) : new Date(0); // Default to a very old date if not present
        const dateB = b.plannedStart ? new Date(b.plannedStart) : new Date(0);
        return dateA - dateB;
      });
      console.log("redddddd", sortedData);

      setRows(sortedData);
    };
    func();
  }, []);
  const handleSaveRow = async (values) => {
    // console.log("vvvvvvvvvvvv", values);
    const body = {
      plannedStart: values.row.original.plannedStart,
      plannedEnd: values.row.original.plannedEnd,
    };
    console.log("bodyy", body);

    await update4D(values.row.original.id, body);
  };

  return (
    <Box p={2}>
      <Stack
        sx={{ paddingBottom: "2rem" }}
        direction={"row"}
        justifyContent="space-between"
        height={"3rem"}
        alignItems="center"
      >
        <h2>Schedule</h2>
      </Stack>
      <Stack direction={"row"}>
        <Box width={"44vw"}>
          {/* <Project4d5d /> */}
          <RowEditableTable
            columns={table4d5dColumns}
            data={rows}
            // handleCreateRow
            handleSaveRow={handleSaveRow}
            // enablePagination={false}
            // enableEditing={true}
            // enableColumnActions={false}
            // enableSorting={false}
            // editDisplayMode="cell"
            // enableBottomToolbar={false}
            // enableRowActions={true}
            // positionActionsColumn="last"
            // {...tableWithBorderedCells}
            // renderRowActionMenuItems={({ row }) => [
            //   <MenuItem key="edit" onClick={() => onTrackItem(row)}>
            //     Track Item
            //   </MenuItem>,
            // ]}
            // // muiTableContainerProps={{ sx: { maxHeight: '70vh' } }}
            // muiEditTextFieldProps={({ cell }) => ({
            //   onBlur: (event) => {
            //     handleSaveCell(cell, event.target.value);
            //   },
            // })}
            // renderTopToolbarCustomActions={(table) => (
            //   <Button
            //     variant="contained"
            //     sx={{ alignSelf: "end", width: "fit-content" }}
            //     onClick={onSave}
            //   >
            //     Save
            //   </Button>
            // )}
            // initialState={{ columnPinning: { left: ["name"] } }}
          />
        </Box>
        <Box width={"40vw"}>
          <TimelineComponent projectId={projectId} rows={rows} />
        </Box>
      </Stack>
      <TrackItemDialog
        open={trackItemDialog.open}
        onClose={onClose}
        item={trackItemDialog.item}
        data={trackItemDialog.data}
      />
    </Box>
  );
};
export default Project4d;

const dialogInitalState = {
  open: false,
  data: [],
  item: {},
};
