import { useFormContext } from "react-hook-form";
import Table from "../../../../../components/Tables/NonEditableTable";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import {
  Button,
  MenuItem,
  Select,
  Typography,
  InputLabel,
  FormControl,
  Grid,
  Paper,
} from "@mui/material";

import EstimationColumns, {
  SummaryColumns,
  NozzleEstimationColumns,
  estCols,
  trimListCols,
  pvEstCols,
  pvSumEst,
} from "./columns";
import { minimalMuiTableProps } from "../../../../../styles/muiProps";
import { useEffect, useState } from "react";
import AddToFabricationDialog from "./AddToFabricationDialog";
import ValidateDialog from "./ValidateDialog";
import { transformsPVEstData } from "./utils";
// import { columns } from "../../CommonFeatures/EstimationTab/utils";

export default function EstimationTab({ projectId }) {
  const { getValues } = useFormContext();

  // const { DGV_Main_Estimation, Est_Sum_DGV, Fab_Rate_Txt, Proposal_Cost_Txt } =
  //   getValues();
  const [DGV_Main_Estimation, setDGV_Main_Estimation] = useState([]);
  const [Est_Sum_DGV, setEst_Sum_DGV] = useState([]);
  const [Fab_Rate_Txt, setFab_Rate_Txt] = useState("");
  const [Proposal_Cost_Txt, setProposal_Cost_Txt] = useState("");
  const [tableData, setTableData] = useState([]);
  const [estData, setEstData] = useState(undefined);

  console.log("est data", getValues());

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isValidateDialogOpen, setValidateDialog] = useState(false);
  const [assembly, setAssembly] = useState("");
  const [rev, setRev] = useState("");
  const revNums = getValues().revisions
    ? getValues().revisions.map((r) => r.name)
    : [];
  // console.log("revssss", getValues());
  const revsData = getValues().revisions
    ? getValues().revisions.map((r) => r.data)
    : "";

  // 'Est.' Cant be used as accessorkey, hence renamed.
  const reformatSummaryTable = Est_Sum_DGV?.map((elem) => ({
    ...elem,
    estimated: elem["Est."],
  }));

  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setValidateDialog(false);
    setIsDialogOpen(false);
  };
  const handleAssemblyChange = (e) => {
    setAssembly(e.target.value);
  };
  const handleRevChange = (e) => {
    // console.log("revision value", e.target.value);

    setRev(e.target.value);
  };
  // useEffect(() => {
  //   const setEstData = () => {
  //     // console.log("revision", revsData[rev]);
  //     const data = revsData[rev];
  //     console.log(data);
  //     if (data) {
  //       setDGV_Main_Estimation(data.DGV_Main_Estimation);
  //       setEst_Sum_DGV(data.Est_Sum_DGV);
  //       setFab_Rate_Txt(data.Fab_Rate_Txt);
  //       setProposal_Cost_Txt(data.Proposal_Cost_Txt);
  //     }
  //   };
  //   setEstData();
  // }, [rev, revsData]);
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    // console.log("hhhhhhhhhhhhhhhhhhh", event);
    if (!file) return;

    try {
      const fileContent = await file.text();

      const jsonData = JSON.parse(fileContent);

      // Set the data from file
      const transformedData = transformsPVEstData(jsonData);
      console.log("Loaded Data:", jsonData, transformedData);
      setTableData(transformedData);
      setEstData(jsonData);
    } catch (error) {
      console.error("Error reading file:", error);
    }
  };
  useEffect(() => {
    const getData = () => {
      if (getValues().revisions[0]) {
        const projData = getValues().revisions[0].data;
        return typeof projData === "string" ? JSON.parse(projData) : projData;
      }
    };
    const data = getData();
    console.log("hereeeee", data.data);
    console.log("hereeeee", data.data.DGV_Estimation_Est);
    setEstData(data.data.DGV_Estimation_Est);

    setTableData(data.data.DGV_Estimation_Est);
  }, [getValues]);
  // useEffect(() => {
  //   console.log("est data", DGV_Main_Estimation);
  // }, [isDialogOpen]);

  // console.log("dddddd", data());

  // // const manhours = [
  // //   data()?.data?.DGV_Estimation_Est[0]["Total Manhour"],
  // //   data()?.data?.DGV_Estimation_Est[1]["Total Manhour"],
  // //   data()?.data?.DGV_Estimation_Est[2]["Total Manhour"],
  // //   "",
  // //   4.9,
  // //   1.55,
  // //   4.95,
  // //   1.8,
  // //   1.55,
  // //   1.55,
  // //   1.55,
  // //   10.65,
  // // ];
  // // const weights = data()?.data?.DGV_Est_BOM.map((row) => row["Total Weight"]);
  // // const pvEstData = [
  // //   ...data()?.data?.DGV_Shell_BOM,
  // //   ...data()?.data?.DGV_Nozzle_Material_Description,
  // // ]
  // //   .map((row, i) => {
  // //     const Components = row.Description || row["Material Description"] || "";
  // //     const weight = row["Total Weight"] || "";
  // //     const cost = row["Cost (US $)"] || row["Total Cost (US $)"];

  // //     return {
  // //       ...row,
  // //       Components,
  // //       // "Total Weight (Kg)": weight,
  // //       "Total Material Cost (US $)": cost,
  // //       "Total Manhour": manhours[i],
  // //       "Total Weight (Kg)": weights[i],
  // //       Unit: row.Unit ? row.Unit : "No.",
  // //     };
  // //   })
  // //   .filter((row) => row.Components !== "Total");

  // const pvEstData = transformsPVEstData(data());
  return (
    <Box>
      <Box sx={{ p: 1, width: "80%" }}>
        <Table
          // columns={EstimationColumns()}
          columns={pvEstCols}
          // columns={estCols}
          // data={
          //   assembly == 1 && rev !== "" && DGV_Main_Estimation
          //     ? DGV_Main_Estimation
          //     : []
          // }
          // data={data()?.Estimation_DGV || []}
          data={tableData}
          additionalProps={{
            renderTopToolbarCustomActions: () => (
              <Box
                sx={{ minWidth: 500, display: "flex", alignItems: "center" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleDialogOpen}
                  sx={{ minWidth: 150, marginRight: 3 }}
                >
                  Add to Fabrication
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setValidateDialog(true);
                  }}
                  sx={{ minWidth: 150, marginRight: 3 }}
                >
                  Validate
                </Button>
                <input
                  type="file"
                  accept=".json"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  id="file-input"
                />
                <label htmlFor="file-input">
                  <Button
                    variant="contained"
                    component="span"
                    sx={{ marginRight: 2 }}
                  >
                    Browse
                  </Button>
                </label>

                {/* <FormControl sx={{ minWidth: 200, marginRight: 1 }}>
                <InputLabel id="assembly-label">Assembly</InputLabel>
                <Select
                  labelId="assembly-label"
                  id="assembly-select"
                  value={assembly}
                  label="Assembly"
                  onChange={handleAssemblyChange}
                >
                  <MenuItem value={1}>Base Proposal</MenuItem>
                  <MenuItem value={2}>Change order1</MenuItem>
                  <MenuItem value={3}>Change order2</MenuItem>
                  {/* <MenuItem value={20}>Twenty</MenuItem>
    <MenuItem value={30}>Thirty</MenuItem> 
                </Select>
              </FormControl> */}
                {/* <FormControl sx={{ minWidth: 200 }}>
                <InputLabel id="rev-label">Rev</InputLabel>
                <Select
                labelId="rev-label"
                  id="rev-select"
                  value={rev}
                  label="Rev"
                  onChange={handleRevChange}
                >
                  {revNums.map((r, index) => (
                    <MenuItem value={index}>{r}</MenuItem>
                  ))}
                  {/* <MenuItem value={1}>0</MenuItem>
                  <MenuItem value={2}>1</MenuItem>
                  <MenuItem value={3}>2</MenuItem> */}
                {/* <MenuItem value={20}>Twenty</MenuItem>
    <MenuItem value={30}>Thirty</MenuItem> 
                </Select>
              </FormControl> */}
              </Box>
            ),
          }}
        />
      </Box>

      {/* <Grid container sx={{ pl: 1 }}>
        <Grid Item xs={6}>
          <Box sx={{ my: 2 }}>
            <Typography
              sx={{ background: "white", py: 1, px: 2, lineHeight: 2 }}
            >
              {" "}
              Engg. and PM Percentage
              <TextField
                sx={{ "& input": { p: 0.5, width: 30 }, mx: 0.5 }}
                value={estData?.data.TextBox6}
              />{" "}
              %
            </Typography>
            <Table
              columns={pvSumEst}
              data={estData?.data.DGV_Estimation_Extra || []}
            />
          </Box>
        </Grid>
      </Grid> */}
      {/* <Grid container spacing={2}>
        <Grid item xs={6} sx={{ my: 1 }}>
          <Table
            columns={trimListCols}
            data={data()?.EstimationSummaryDescription_DGV || []}
          />
        </Grid>
        <Grid item xs={6}>
          <Paper sx={{ my: 1, borderRadius: "5px" }}>
            <Grid
              container
              sx={{
                // backgroundColor: "#fff",
                p: 5.4,
                "& > *": { p: 1, fontWeight: "bold" },
              }}
            >
              <Grid item xs={6}>
                Component Total Cost
              </Grid>
              <Grid item xs={6}>
                {data()?.txtComponentsTotalCost || ""}
                </Grid>

              <Grid item xs={6}>
                Fabrication Labour Total Cost
              </Grid>
              <Grid item xs={6}>
                {data()?.txtFabricationLabourTotalCost || ""}
              </Grid>

              <Grid item xs={6}>
                Engineering Design Total and Cost
              </Grid>
              <Grid item xs={6}>
                {data()?.txtEngineeringDesignTotalCost || ""}
              </Grid>

              <Grid item xs={6}>
                Testing and Shipping Total Cost
              </Grid>
              <Grid item xs={6}>
                {data()?.txtTestingAndShippingTotalCost || ""}
              </Grid>

              <Grid item xs={6}>
                Total Components nd Labour Cost
              </Grid>
              <Grid item xs={6}>
                {data()?.txtTotalComponentsAndLabourCost || ""}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid> */}

      {/* Add to Fabrication Dialog */}
      <AddToFabricationDialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        data={[...tableData].splice(0, tableData.length - 5) || []}
      />
      <ValidateDialog
        open={isValidateDialogOpen}
        onClose={handleDialogClose}
        data={revsData || []}
      />

      {/* Summary Box */}
      {/*   <Stack direction={"row"} gap={1}>
        <Box
        sx={{ backgroundColor: "white", borderRadius: "5px" }}
          mt={5}
          width={"50%"}
          p={2}
          >
          <Stack pb={1} direction={"row"} alignItems={"center"}>
            <Typography>Fabrication Rate</Typography>
            
            <TextField
            sx={{ paddingLeft: 3, paddingRight: 3 }}
              id="fab-rate"
              value={
                assembly == 1 && rev !== "" && Fab_Rate_Txt ? Fab_Rate_Txt : ""
              }
              // label="Fabrication Rate"
              variant="outlined"
              size="small"
              contentEditable={false}
            />
            <Typography>$/hr</Typography>
          </Stack>
          <Table
            columns={SummaryColumns()}
            data={
              assembly == 1 && rev !== "" && reformatSummaryTable
                ? reformatSummaryTable
                : []
            }
            additionalProps={{
              ...minimalMuiTableProps,
              enableTopToolbar: false,
              enableBottomToolbar: false,
            }}
          />
          <Stack pt={1} direction={"row"} alignItems={"center"}>
            <Typography>Proposal Cost</Typography>

            <TextField
              sx={{ paddingLeft: 3, paddingRight: 3 }}
              id="prop-cost"
              size="small"
              value={
                assembly == 1 && Proposal_Cost_Txt ? Proposal_Cost_Txt : ""
              }
              // label="Proposal Cost"
              variant="outlined"
              contentEditable={false}
              disabled={true}
            />
            <Typography>$</Typography>
          </Stack>
        </Box> */}
      {/* <Stack
          // justifyContent={"center"}
          // alignItems={"center"}
          sx={{ backgroundColor: "white" }}
          mt={5}
          width={"50%"}
        >
          <Typography variant="h4" alignSelf={"center"}>
            Nozzle Estimation Data
          </Typography>
          <Table columns={NozzleEstimationColumns()} data={[]} />
        </Stack> 
      </Stack>*/}
    </Box>
  );
}
