import React, { createContext, useContext } from "react";
import { useParams } from "react-router-dom";
import { getMrqData, getMrqPos, getProject } from "./api";
import { getMrqBid } from "./BidTab/api";
// import { getMrqData, getMrqPos, getMrqs } from './api';
// import { getMrqBid } from './BidTab/api';
// import { getBidTableRows } from './BidTab/BidTable/BidTableBody/getRows';
import { getBidTableRows } from "./BidTab/BidTable/BidTableBody/getRows";

const MCSContext = createContext();

export const useMcsContext = () => useContext(MCSContext);

function MCSContextProvider({ children }) {
  const projectId = useParams();
  const [project, setProject] = React.useState({
    id: projectId.id,
    drawings: [],
    mrqs: [],
    pos: [],
    mrvs: [],
    srvs: [],

    selectedDrawings: [],
    selectedMrq: {},
    selectedPo: {},
  });
  const [currentTab, setCurrentTab] = React.useState(0);

  const setSelectedDrawings = (drawings) => {
    setProject({ ...project, selectedDrawings: drawings });
  };

  const setSelectedMrq = (mrq) => {
    setProject({ ...project, selectedMrq: {} });
    setProject({ ...project, selectedMrq: mrq });
  };

  const setSelectedPoManager = (po) => {
    setProject({ ...project, selectedPo: po });
  };

  const updateProjectAfterCreateMrq = (res) => {
    console.log({ res });
    setProject({
      ...project,
      selectedDrawings: res.drawings,
      selectedMrq: res.mrq,
    });
  };

  React.useEffect(() => {
    const func = async () => {
      const data = await getProject({ projectId: project.id });
      setProject({ ...project, ...data });
    };
    func();
  }, []);

  console.log({ project });

  const [bidData, setBidData] = React.useState([]);
  const [BidTableRows, setBidTableRows] = React.useState([]);

  const [selectedBidVendors, setSelectedVendors] = React.useState([]);
  const [selectedMrqMaterials, setSelectedMrqMaterials] = React.useState([]);

  // Handler for selecting MRQ

  // React.useEffect(() => {
  //   const fetchMrqThenBidData = async () => {
  //     const mrq = await getMrqData(
  //       mrqSelection.selectedRevision || mrqSelection.selectedMrq
  //     );
  //     setSelectedMrq(mrq);
  //     setSelectedVendors(mrq.vendors?.map((v) => v.name));

  //     const bids = await getMrqBid(
  //       mrqSelection.selectedRevision || mrqSelection.selectedMrq
  //     );
  //     const existedPos = await getMrqPos(
  //       mrqSelection.selectedRevision || mrqSelection.selectedMrq
  //     );
  //     setBidData(bids ? bids : []);

  //     setBidTableRows();
  //     setBidTableRows(
  //       getBidTableRows({
  //         mrqData: mrq ? mrq : {},
  //         bidData: bids ? bids : [],
  //         existedPos,
  //       })
  //     );
  //   };

  //   fetchMrqThenBidData();
  // }, [mrqSelection]);

  return (
    <MCSContext.Provider
      value={{
        currentTab,
        setCurrentTab,
        project,
        setProject,
        setSelectedDrawings,
        setSelectedMrq,
        setSelectedPoManager,
        // setMrqSelection,

        selectedMrqMaterials,
        setSelectedMrqMaterials,
        updateProjectAfterCreateMrq,

        // mrqList,
        // setMrqList,
        // mrqSelection,
        // mrqData,
        // handleMrqChange,
        // handleRevisionChange,
        selectedBidVendors,
        setSelectedVendors,
        bidData: bidData ? bidData : [],
        setBidData,
        BidTableRows,
        setBidTableRows,
      }}
    >
      {children}
    </MCSContext.Provider>
  );
}

export { MCSContextProvider, MCSContext };
