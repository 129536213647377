import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { Box, Stack } from "@mui/material";
import { Button } from "@mui/material";

import { useNavigate } from "react-router-dom";
// import NewProjectsBox from "./NewProjectsBox";
// import ProjectsTable from "./ProjectsTable";
import ProjectMembersModal from "./ProjectsTable/ProjectMembersModal";
import paths from "../../../../api/paths.api";
import { EditableTable } from "./ProjectsTable/ProjectEditableTable";
import { columns } from "./ProjectsTable/columns";
import {
  createProject,
  deleteProject,
  getAllProjects,
  updateProject,
} from "./api";

const MainProjects = () => {
  const navigate = useNavigate();

  const [memberModalOpen, setMembersModalOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  // const { role } = useContext(AuthContext);
  const navigateToTaskList = (row) => {
    // get id of project here
    const id = row;
    navigate(`${paths.myProjects}/${id}`);
  };

  const additionalProps = {
    muiTableBodyRowProps: ({ row }) => ({
      onClick: (event) => {
        const target = event.target;
        if (!target.closest(".MuiButtonBase-root") && !target.closest("a")) {
          // navigateToTaskList(row.id);
        }
      },
      sx: {
        // cursor: 'pointer', //change the cursor when adding an onClick
      },
    }),
    defaultColumn: {
      minSize: 20,
    },
  };

  const gotToMcs = (id) => {
    navigate(
      `/projects/${id}/equipments/main_tank#main-nav=MCS&mcs-tab=Drawing%20BOM`
    );
  };

  const openMembersModal = (id) => {
    if (id) {
      setSelectedProject(id);
      setMembersModalOpen(true);
    }
  };

  return (
    <Box>
      <Box
        sx={{
          pt: 1,
          pl: "4rem",
          pr: "4rem",
          pb: 0,
          minHeight: "95vh",
          background:
            "linear-gradient(90deg, hsla(253, 82%, 98%, 1) 30%, hsla(217, 100%, 93%, 1) 100%);",
        }}
      >
        {/* <Typography variant="h5">Welcome to ChinookDT! </Typography> */}
        <Box
          sx={{
            pt: 3,

            background:
              "linear-gradient(90deg, hsla(253, 82%, 98%, 1) 30%, hsla(217, 100%, 93%, 1) 100%);",
            height: "100%",
          }}
        >
          {/* Table */}
          {/* <ProjectsTable data={data && data} state={dataState} /> */}

          <EditableTable
            name={"Projects"}
            columns={columns(gotToMcs, openMembersModal)}
            api={{
              getItems: getAllProjects,
              createItem: createProject,
              updateItem: updateProject,
              deleteItem: deleteProject,
            }}
            enableCreate={true}
            enableEditing={true}
          />
          <ProjectMembersModal
            open={memberModalOpen}
            onClose={() => {
              setMembersModalOpen(false);
            }}
            projectId={selectedProject}
          />
          <br />
        </Box>
      </Box>
    </Box>
  );
};

export default MainProjects;
