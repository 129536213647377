import {
  Box,
  Button,
  Collapse,
  Grid,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import SubMaterialTable from "./SubMaterialTable";
import DesignTable from "./DesignTable";
import { mainMaterialsTablesCols } from "./col";
import { MCSContext } from "../../MCS.context";
import ReconcileBtn from "../ReconcileBtn";

const gridLen = [3.5, 8.5];
export default function Row({ row }) {
  const [open, setOpen] = React.useState(false);

  const { setCurrentTab } = React.useContext(MCSContext);

  const onCreateNewMrq = () => {
    setCurrentTab(1);
  };

  let quantity, unit;
  if (row.length) {
    // console.log({ len: row.length });
    unit = row.length.split(" ").pop();

    quantity = row.length.match(/\d+\.\d+/)[0];
    // console.log({ quantity, unit });

    row = { ...row, unit, quantity };
  } else {
    row = { ...row, unit: "No." };
  }

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell sx={{ width: 50 }}>
          <IconButton
            aria-label="expand row"
            size="small"
            sx={{ mr: 1 }}
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        {mainMaterialsTablesCols.map((col) => {
          const cellValue = row[col.accessorKey];

          return (
            <TableCell sx={{ border: "1px solid #ddd" }} key={col.accessorKey}>
              {cellValue}
            </TableCell>
          );
        })}
      </TableRow>
      <TableRow>
        <TableCell
          sx={{
            p: 0,
          }}
          colSpan={16}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ p: 2 }}>
              <Grid container>
                <Grid item xs={gridLen[0]} sx={{ pr: 2 }}>
                  <Typography variant="h6" gutterBottom component="div">
                    Included in :
                  </Typography>
                </Grid>
                <Grid item xs={gridLen[1]}>
                  <Typography variant="h6" gutterBottom component="div">
                    {row.mrqMaterials[0]?.description
                      ? `Material (${row.mrqMaterials[0]?.description}) Timeline :`
                      : `No Material Timeline `}

                    <ReconcileBtn row={row} />
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={gridLen[0]} sx={{ pr: 2 }}>
                  <DesignTable drawings={row.drawings} />
                </Grid>
                <Grid item xs={gridLen[1]}>
                  {row.mrqMaterials[0]?.description ? (
                    <SubMaterialTable timeline={row.timeline} />
                  ) : (
                    <Box sx={{ textAlign: "center" }}>
                      <Button variant="contained" onClick={onCreateNewMrq}>
                        Create New MRQ Now
                      </Button>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
