import React from "react";
import MainBidTab from "./Main";
import { MrqContextProvider } from "../MRQTab/MrqContext";

export default function BidTab() {
  return (
    <MrqContextProvider>
      <MainBidTab />
    </MrqContextProvider>
  );
}
