import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Modal,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { modalStyle } from "../../../../../../components/modalStyle";

export default function ReconcileBtn({ row }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Button
        variant="contained"
        sx={{ float: "right", mb: 0.8 }}
        onClick={handleOpen}
      >
        Reconcile
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography variant="h6" component="h2" sx={{ fontWeight: "bold" }}>
            Reconcile Material with new PO :
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField label="PO #" fullWidth sx={{ m: 0.5 }} />
              <TextField fullWidth type="date" sx={{ m: 0.5 }} />
              <TextField fullWidth label="Vendor" sx={{ m: 0.5 }} />
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="h6"
                component="h2"
                sx={{ fontWeight: "bold" }}
              >
                Material :
              </Typography>
              <TextField
                disabled
                value={row?.mrqMaterials[0]?.description}
                sx={{ m: 0.5 }}
                fullWidth
              />

              <TextField
                label={"Required Quantity (" + row.unit + ")"}
                sx={{ m: 0.5 }}
                fullWidth
                type="number"
              />
            </Grid>
          </Grid>

          <Box sx={{ textAlign: "right", my: 1 }}>
            <Button variant="outlined" onClick={handleClose}>
              close
            </Button>
            <Button variant="contained" sx={{ ml: 1 }}>
              Create New PO
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
